<!--
 * @Description: 多图上传、可拖拽、粘贴，不需要剪裁，相册列表展示。上传成功后返回所有的url数组
 * @Author: 琢磨先生
 * @Date: 2023-12-15 10:26:46
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-16 08:51:34
-->
<template>
  <div class="upload-box">
    <el-upload
      ref="upload"
      class="avatar-uploader"
      action=""
      multiple
      list-type="picture-card"
      v-model:file-list="fileList"
      :http-request="onUpload"
      :on-preview="handlePictureCardPreview"
      :on-remove="handlerPictureRemove"
      accept=".jpg,.jpeg,.png,.gif"
    >
      <div style="display: flex; flex-direction: column; align-items: center">
        <el-icon class="el-icon--upload" size="40"><upload-filled /></el-icon>
        <div class="el-upload__text">
          粘贴 或<em style="color: var(--el-color-primary)">点击选择</em>
        </div>
      </div>
      <template #tip>
        <div class="el-upload__tip">仅支持.jpg,.jpeg,.png,.gif格式图片</div>
      </template> 
    </el-upload>
  </div> 
  <el-dialog v-model="dialogVisible">
    <img w-full :src="dialogImageUrl" alt="Preview Image" />
  </el-dialog>
</template>

<script>
import md5_util from "@/utils/md5_util.js";
import axios from "axios";

export default {
  data() {
    return {
      // imageUrl: null,
      // saving: false,
      // percentage: 0,
      originalUrl: "",
      srcList: [], //已经上传的图片地址列表
      fileList: [],
      dialogImageUrl: "", //浏览
      dialogVisible: false,
    };
  },
  props: {
    pictures: Array,
    ak: String,
    bucket: {
      type: String,
      default: "image",
    },
  },
  emits: ["change"],
  watch: {
    pictures: {
      handler() {
        if (this.pictures) {
          this.fileList = this.pictures.map((x) => {
            return {
              url: x,
            };
          });
          this.srcList = this.pictures;
          this.$emit("change", this.srcList, this.ak);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener("paste", this.handlerPaste);
  },
  unmounted() {
    document.removeEventListener("paste", this.handlerPaste);
  },
  methods: {
    /**
     * 上传
     */
    onUpload(ctx) {
      console.log(ctx);
      //处理预览
      let reader = new FileReader();
      reader.readAsDataURL(ctx.file);
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.uploadMfsHandler(ctx.file);
      };

      this.saving = true;
    },

    uploadMfsHandler: async function (file) {
      var self = this;
      //获取md5值
      var md5 = await md5_util.fileMd5(file);
      //获取图片长宽
      await this.handlerWH(file);

      var model = {
        height: file.height,
        width: file.width,
        size: file.size,
        mfsType: 0,
        name: file.name,
        ext: `.${file.type.replace("image/", "")}`,
        md5: md5,
      };

      //
      var pre_file = this.fileList.find((o) => o.uid == file.uid);
      pre_file.status = "uploading";

      var res = await this.$http.get("admin/v1/common/mfs/file_md5?md5=" + md5);
      if (res.code == 0) {
        if (res.data) {
          this.saving = false;
          // this.percentage = 100.0;
          pre_file.percentage = 100.0;
          pre_file.url = res.data.fileUrl;
          pre_file.status = "success";
          this.srcList.push(res.data.fileUrl);
          this.$emit("change", this.srcList, this.ak);
          return;
        }
      }

      this.$http
        .post("admin/v1/common/mfs/get_put_url", {
          bucket: this.bucket,
          ext: model.ext,
        })
        .then((res) => {
          if (res.code == 0 && res.data) {
            axios
              .put(res.data.upload_url, file, {
                headers: { "content-type": file.type },
                onUploadProgress(m) {
                  // self.percentage = m.progress * 100;
                  pre_file.percentage = m.progress * 100;
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  pre_file.status = "success";
                  pre_file.url = res.data.download_url;

                  this.srcList.push(res.data.download_url);
                  self.$emit("change", this.srcList, self.ak);
                  model.fileUrl = res.data.download_url;
                  self.$http.post("admin/v1/common/mfs/add", model);
                }
              });
          }
        })
        .finally(() => (this.saving = false));
    },

    /**
     * 处理获取图片的宽高
     */
    handlerWH(file) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = function () {
          file.width = img.width;
          file.height = img.height;
          resolve(file);
        };
        img.src = URL.createObjectURL(file);
      });
    },

    /**
     * 删除原有图片,并回调空字符串地址
     */
    onDelete() {
      this.originalUrl = null;
      this.$emit("change", "", this.ak);
    },

    /**
     * 粘贴处理
     * @param {*} e
     */
    handlerPaste(e) {
      var clip = e.clipboardData || window.clipboardData;
      if (clip.items) {
        var file = clip.items[0].getAsFile();
        if (file) {
          this.$refs.upload.handleStart(file);
          this.$refs.upload.submit();
        }
      }
    },

    /**
     * 预览
     */
    handlePictureCardPreview(uploadFile) {
      this.dialogVisible = true;
      this.dialogImageUrl = uploadFile.url;
    },

    /**
     * 移除
     * @param {*} file
     */
    handlerPictureRemove(file) {
      var i = this.srcList.findIndex((o) => o == file.url);
      if (i > -1) {
        this.srcList.splice(i, 1);
        this.$emit("change", this.srcList, this.ak);
      }
    },
  },
};
</script>

<style scoped>
.upload-box {
  position: relative;
  cursor: pointer;
  transition: var(--el-transition-duration-fast);
}
.avatar-uploader {
  position: relative;
}
.original-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.upload-box .original-box .btn-delete {
  position: absolute;
  right: 0;
  top: 0;
}

/* 预览 */
.preview-box {
  position: relative;
  width: 120px;
  height: 120px;
}

.progress {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style>
.avatar-uploader .el-progress__text {
  color: white;
}

/* .avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
} */

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  text-align: center;
}
</style>
