import http from "./axios_init";
export default {
 
  /**
   * 获取 enum 的参数
   * @returns
   */
  get_enums() {
    return http.get("v1/common/enums");
  },
  };
  