<template>
  <el-dialog
    title="上传二维码"
    v-model="visible"
    width="800"
    draggable 
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    @closed="onClosed"
  >
    <el-descriptions v-if="item">
      <el-descriptions-item label="系统编号">{{
        item.id
      }}</el-descriptions-item>
      <el-descriptions-item label="报备单号">{{
        item.hNumber
      }}</el-descriptions-item>
      <el-descriptions-item label="公司名称">{{
        item.company
      }}</el-descriptions-item>
      <el-descriptions-item label="带看项目">{{
        item.estateName
      }}</el-descriptions-item>
      <el-descriptions-item label="带看人"
        >{{ item.daikanName }}/{{ item.daikanMobile }}</el-descriptions-item
      >
      <el-descriptions-item label="报备人"
        >{{ item.consumerName }}/{{
          item.consumerMobile
        }}</el-descriptions-item
      >
      <el-descriptions-item label="客户姓名">{{
        item.clientName
      }}</el-descriptions-item>
      <el-descriptions-item label="客户性别">{{
        item.sex
      }}</el-descriptions-item>
      <el-descriptions-item label="客户手机">{{
        item.clientMobile
      }}</el-descriptions-item>
      <el-descriptions-item label="到案人数">{{
        item.dueNum
      }}</el-descriptions-item>
      <el-descriptions-item label="到案时间">{{
        item.dueTime
      }}</el-descriptions-item>
      <el-descriptions-item label="报备备注">{{
        item.remark
      }}</el-descriptions-item>
      <el-descriptions-item label="报备时间">{{
        item.creationTime
      }}</el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-link type="primary" :underline="false">{{
          item.cur_status_text
        }}</el-link>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider></el-divider>
    <upload-single @change="onUploadChange"></upload-single>
    <template #footer>
      <el-button @click="visible = false" :disabled="saving">取消</el-button>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import UploadSingle from "@/views/component/upload_single_drag.vue";

export default {
  components: {
    UploadSingle,
  },
  data() {
    return {
      visible: false,
      saving: false,
      form: {},
      rules: {},
    };
  },
  props: {
    item: Object,
  },
  emits: ["closed", "success"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.form = {
            id: this.item.id,
          };
          this.visible = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onClosed() {
      this.$emit("closed");
    },

    /**
     * 结果选择更改
     * @param {*} o
     */
    onStatusChange(o) {
      this.$refs.form.clearValidate();
      if (o == 3) {
        this.rules.resultContent = [
          { required: true, message: "请输入", trigger: "blur" },
        ];
      } else {
        delete this.rules.resultContent;
      }
    },

    /**
     * 图片上传回调
     * @param {*} url
     */
    onUploadChange(url) {
      this.form.picUrl = url;
    },

    /**
     * 提交
     */
    onSubmit() {
      if (!this.form.picUrl) {
        this.$message.warning("请上传二维码图片");
        return;
      }
      this.saving = true;
      this.$http
        .post("admin/v1/reportQrCode/upload", this.form)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.visible = false;
            this.$emit("success");
          }
        })
        .finally(() => (this.saving = false));
    },
  },
};
</script>

<style scoped></style>
