<!--
 * @Description: 项目列表盒子
 * @Author: 琢磨先生
 * @Date: 2024-01-19 16:56:19
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-25 09:51:45
-->
<template>
  <el-card class="box data">
    <el-form>
      <el-form-item label>
        <el-input
          v-model="q"
          placeholder="关键字搜索楼盘项目"
          clearable
          @input="keyChange"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="estate_box">
      <el-scrollbar>
        <el-checkbox-group v-model="estateIds" @change="estateChange"  >
          <div class="item" v-for="item in estate_list" :key="item.id">
            <el-checkbox :label="item.id" style="color: #337ab7;"  >
              {{ item.name }}
              <span v-if="item.tagAlias">({{ item.tagAlias }})</span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </el-scrollbar>
    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      q: "",
      estateIds: [],
      estate_list: [],
    };
  },
  emits: ["change"],
  created() {
    this.$http.get("admin/v1/reportNew/estate").then((res) => {
      if (res.code == 0) {
        this.estates = res.data;
        this.estate_list = this.estates;
      }
    });
  },

  methods: {
    /**
     * 关键字搜索楼盘
     * @param {*} val
     */
    keyChange(val) {
      this.estateIds = [];
      this.estate_list = this.estates.filter((x) => x.name.indexOf(val) > -1);
      if (!val) {
        this.estateChange();
      }
    },
    /**
     * 楼盘选择更改
     */
    estateChange() {
      this.$emit("change", this.estateIds);
    },
  },
};
</script>

<style scoped>

</style>
